import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { Link } from 'react-router-dom'
import './style.scss';
import purpleBackground from '../../../resources/images/technologies/purple-background.svg';
import purplePhone from '../../../resources/images/technologies/purple-phone.png';
import ROUTERS from 'constants/Routers';


const MentalHealth = () => {
  const phoneWrapperRef = useRef(null);
  const isInViewFirstElement = useInView(phoneWrapperRef, { once: true });

  return (
    <div className="mental-health">
      <div className="mental-health__container">
        <div className="left">
          <h1>Earkick Mental Health AI</h1>
          <h5>
            Earkick's technology is taking a unique and novel approach that
            enables fine grained and quantifiable mental health monitoring and
            assessment to improve the user's mental health and quality of life.
          </h5>
          <h5>
            To monitor mental health, we combine various sources of data to
            better understand the context in which a user is situated and
            potentially influences the user's mental health.
          </h5>
          {false &&
            <div className="button-wrapper">
              <Link className="blue-button" to={ROUTERS.TRY_NOW_DEMO}>
                Try it now!
              </Link>
            </div>
          }
        </div>
        <div className="right">
          <motion.div
            ref={phoneWrapperRef}
            className="phone-background"
            initial={!isInViewFirstElement && 'hidden'}
            animate={isInViewFirstElement && 'show'}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ type: 'spring', duration: 2 }}
            >
              <img
                className="background"
                src={purpleBackground}
                alt="Phone yellow background"
              />
            </motion.div>
            <motion.div
              initial={{ y: 100 }}
              animate={{ y: 3 }}
              transition={{ type: 'spring', duration: 2 }}
            >
              <img className="phone" src={purplePhone} alt="Phone" />
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default MentalHealth;
