import React from 'react';
import './style.scss';
import smartMentalImage from '../../../resources/images/technologies/smart-mental.png';
import decodeImage from '../../../resources/images/technologies/decode-image.png';
import healthImage from '../../../resources/images/technologies/health-image.png';
import privatizedImage from '../../../resources/images/technologies/privatized-image.png';

const Novelty = () => {
  return (
    <div className="novelty">
      <div className="novelty__container">
        <h2 className="h2">The novelty of our approach lies in:</h2>
        <div className="content">
          <div className="element">
            <h3 className="h3">
              <span>01.</span> Smart mental health assessment
            </h3>
            <img
              src={smartMentalImage}
              alt="Smart mental health assessment"
              loading="lazy"
            />
            <p className="p">
              The integration of numerous channels of information in an
              innovative way, where each channel provides a different
              perspective and additional value to solve the puzzle that is
              mental health assessment.
              <br />
              <span>(Status: App based measurements completed).</span>
            </p>
          </div>

          <div className="element">
            <h3 className="h3">
              <span>02.</span> Decode a user's emotional state
            </h3>
            <img
              src={decodeImage}
              alt="Decode a user's emotional state"
              loading="lazy"
            />
            <p className="p">
              Use of latest machine learning models to decode a user’s emotional
              state, create features to predict outcomes such as anxiety levels,
              mood disorders, panic attacks and other irregularities in
              behavior.
              <br />
              <span>(Status: First models are prepared).</span>
            </p>
          </div>

          <div className="element">
            <h3 className="h3">
              <span>03.</span>Health exercises based on you
            </h3>
            <img
              src={healthImage}
              alt="Health exercises based on you"
              loading="lazy"
            />
            <p className="p">
              Recommending in-the-moment cognitive behavior therapy based mental
              health exercises informed by our emotion recognition and
              mood/anxiety prediction models.
              <br />
              <span>(Status: upcoming feature).</span>
            </p>
          </div>

          <div className="element">
            <h3 className="h3">
              <span>04.</span>Privatized learning
            </h3>
            <img
              src={privatizedImage}
              alt="Privatized learning"
              loading="lazy"
            />
            <p className="p">
              Built-in privatized learning that protects the users’ identity,
              because we do not collect email addresses or demographic data such
              as name, age, gender or location.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Novelty;
