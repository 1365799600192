import { Suspense, useEffect, useState } from 'react';
import './style.scss';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

const Video = () => {
  const [poster, setPoster] = useState('default');

  useEffect(() => {
    const timer = setTimeout(() => {
      setPoster('maxresdefault');
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="video">
        <div className="video__container">
          <LiteYouTubeEmbed
            id="ql5UQx1RdpU"
            title="Your Self Care Companion Earkick"
            poster={poster}
        />
        </div>
      </div>
    </Suspense>
  )
};

export default Video;
