import './style.scss';

import timeLogo from '../../../resources/images/dashboard/time-logo.svg';
import thoThisWeek from '../../../resources/images/home-apple-logo.svg';
import forbesLogo from '../../../resources/images/forbes-health.png';
import bbcLogo from '../../../resources/images/bbc.png';
import guardianLogo from '../../../resources/images/dashboard/the-guardian.png'
import apLogo from '../../../resources/images/dashboard/ap-logo.png'
import nationalGeographicLogo from '../../../resources/images/national-geographic.png'

import { Suspense } from 'react';


const PRE_PARTNERS = [
  {
    id: 1,
    name: 'Hot this week',
    image: thoThisWeek,
    link: false,
  },
];

const PARTNERS = [
  { 
    id: 100, 
    name: 'National Geographic', 
    image: nationalGeographicLogo, 
    link: 'https://www.nationalgeographic.com/science/article/ai-chatbots-treatment-mental-health' 
  },
  {
    id: 2,
    name: 'time',
    image: timeLogo,
    link: 'https://time.com/6320378/ai-therapy-chatbots',
  },
  {
    id: 3,
    name: 'forbes',
    image: forbesLogo,
    link: 'https://www.forbes.com/health/mind/ai-therapy/',
  },
  {
    id: 4,
    name: 'bbc',
    image: bbcLogo,
    link: 'https://www.bbc.com/news/technology-67872693',
    width: 150
  },
  {
    id: 5,
    name: 'guardian',
    image: guardianLogo,
    link: 'https://www.theguardian.com/lifeandstyle/2024/mar/02/can-ai-chatbot-therapists-do-better-than-the-real-thing',
    width: 150
  },
  {
    id: 6,
    name: 'Associated Press',
    image: apLogo,
    link: 'https://apnews.com/article/chatbots-mental-health-therapy-counseling-ai-73feb819ff52a51d53fee117c3207219',
    width: 90
  }
];


const Feature = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="pre-feature">
        <div className="feature__container">
          <div className="partners">
            {PRE_PARTNERS.map((partner, index) => {
                return (
                  <div key={`${partner.name}-${index}`} className="partner">
                    {partner.link &&
                      <a
                      href={partner.link}
                      alt={partner.name}
                      target="_blank"
                      rel="noreferrer"
                      >
                        <img src={partner.image} width={partner.width || 150} alt={partner.name} loading="lazy" />
                      </a>
                    }
                    {!partner.link &&
                      <img src={partner.image} width={partner.width} alt={partner.name} loading="lazy" />
                    }
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="feature">
        <div className="feature__container">
          <div className="partners">
            {PARTNERS.map((partner, index) => {
              return (
                <div key={`${partner.name}-${index}`} className="partner">
                  {partner.link &&
                    <a
                    href={partner.link}
                    alt={partner.name}
                    target="_blank"
                    rel="noreferrer"
                    >
                      <img src={partner.image} width={partner.width || 150} alt={partner.name} loading="lazy" />
                    </a>
                  }
                  {!partner.link &&
                    <img src={partner.image} width={partner.width} alt={partner.name} loading="lazy" />
                  }
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Feature;
