import './style.scss';
import React, { Suspense } from 'react';
import BorderedText from '../../bordered-text';
import a1 from '../../../resources/images/dashboard/1.svg';
import a2 from '../../../resources/images/dashboard/2.svg';
import a3 from '../../../resources/images/dashboard/3.svg';
import a4 from '../../../resources/images/dashboard/4.svg';
import a5 from '../../../resources/images/dashboard/5.svg';
import a6 from '../../../resources/images/dashboard/6.svg';
import a7 from '../../../resources/images/dashboard/7.svg';
import a8 from '../../../resources/images/dashboard/8.svg';
import a9 from '../../../resources/images/dashboard/9.svg';
import bluePhoneAlan from '../../../resources/images/dashboard/blue-phone-alan.png';

const data = [
  { id: 1, name: '', image: a1, text: 'Track your mood in just seconds' },
  { id: 2, name: '', image: a2, text: 'Add voice memos on the go' },
  { id: 3, name: '', image: a3, text: 'Set achievable routines easily' },
  { id: 4, name: '', image: a4, text: 'Add video memos on the go' },
  {
    id: 5,
    name: '',
    image: a5,
    text: 'Learn breathing techniques to combat stress',
  },
  { id: 6, name: '', image: a6, text: 'Get support based on your data' },
  { id: 7, name: '', image: a7, text: 'Measure heart rate during sessions' },
  {
    id: 8,
    name: '',
    image: a8,
    text: 'Get daily quotes based on your situation',
  },
  { id: 9, name: '', image: a9, text: 'Connect to your Apple Watch' },
];

const MainFeaturesList = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="main-feature-list">
        <div className="main-feature-list__container">
          <h2 className="h2">
            Main
            <BorderedText
              text="features"
              textColor="#5E8CFF"
              backgroundColor="#D4E0FF"
              rotate="-4"
            />
          </h2>
          <div className="content">
            {data.map((item) => {
              return (
                <div key={item.id} className="element">
                  <img src={item.image} alt={item.text} />
                  <h4 className="h4">{item.text}</h4>
                </div>
              );
            })}
          </div>

          <div className="privacy-content">
            <div className="privacy-content__container">
              <div className="privacy-content__container-inner first">
                <img
                  src={bluePhoneAlan}
                  alt="Blue phone, what are your main goals?"
                />
              </div>
              <div className="privacy-content__container-inner second">
                <h2 className="h2">
                  Your privacy is
                  <BorderedText
                    text="key"
                    textColor="#EC6F47"
                    backgroundColor="#FFD2C3"
                    rotate="-4"
                  />
                </h2>

                <p>
                  No registration is required to use Earkick. This means that we
                  have no personal data whatsoever about you. Your data belongs to
                  you and only to you.
                </p>

                <p>
                  We are not exposing it to third parties. Your data serves to
                  support you and is used to build better tools for everyone who
                  wants to manage anxiety in the future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default MainFeaturesList;
