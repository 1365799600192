import './style.scss';
import instagramIcon from '../../resources/images/dashboard/instagram.svg';
import facebookIcon from '../../resources/images/dashboard/facebook.svg';
import twitterIcon from '../../resources/images/dashboard/twitter.svg';
import youtubeIcon from '../../resources/images/dashboard/youtube.svg';
import linkedinIcon from '../../resources/images/dashboard/linkedin.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    
    <span className="footer" id="footer">{ /* I am sad to write that this span is for reason because react-snap replaced footer div with main content div, no clue why and footer styles were broken. */ } 
      <div className="footer__container">
        <div className="socials">
          <a
            href="https://www.instagram.com/earkick/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="element">
              <img src={instagramIcon} alt="Instagram" loading="lazy" />
            </div>
          </a>
          <a
            href="https://www.facebook.com/earkick"
            target="_blank"
            rel="noreferrer"
          >
            <div className="element">
              <img src={facebookIcon} alt="Faceboook" loading="lazy" />
            </div>
          </a>
          <a
            href="https://twitter.com/earkickapp"
            target="_blank"
            rel="noreferrer"
          >
            <div className="element">
              <img src={twitterIcon} alt="Twitter" loading="lazy" />
            </div>
          </a>
          <a
            href="https://www.youtube.com/channel/UCjqXTMgHNd0LlncJBGeV8cA"
            target="_blank"
            rel="noreferrer"
          >
            <div className="element">
              <img src={youtubeIcon} alt="Youtube" loading="lazy" />
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/76142134/admin/"
            target="_blank"
            rel="noreferrer"
          >
            <div className="element">
              <img src={linkedinIcon} alt="Linkedin" loading="lazy" />
            </div>
          </a>
        </div>
        <br /><br />
        <Link
          className="privacy-style"
          to="/privacy"
        >
          Privacy Policy
        </Link>
        <br /><br />
        <Link
          className="privacy-style"
          to="/terms"
        >
          Terms &amp; Conditions
        </Link>
      </div>
      <p className="pt-5">Earkick AI Therapist is not a licensed psychologist or psychiatrist and should not be considered a substitute for professional mental health care. It is intended for educational and self-improvement purposes only. If you are experiencing a mental health crisis or need professional support, please seek help from a qualified healthcare provider.</p>
    </span>
  );
};

export default Footer;
