import React from 'react';
import './style.scss';

const Resources = () => {
  return (
    <div className="resources">
      <div className="resources__container">
        <h2 className="h2">Research</h2>

        <p>
          <span><a href="https://openaccess.thecvf.com/content/CVPR2023W/ABAW/papers/Palotti_Analysis_of_Emotion_Annotation_Strength_Improves_Generalization_in_Speech_Emotion_CVPRW_2023_paper.pdf">[1]</a></span> J. Palotti, G. Narula, L. Raheem, H. Bay, 'Analysis of 
          Emotion Annotation Strength Improves Generalization in Speech Emotion 
          Recognition Models'. Proceedings of the IEEE/CVF Conference on Computer 
          Vision and Pattern Recognition CVPR2023W
        </p>

        <p>
          <span>[2]</span> D. A. Kalmbach, J. R. Anderson, and C. L. Drake, 'The
          impact of stress on sleep: Pathogenic sleep reactivity as a
          vulnerability toinsomnia and circadian disorders', J. Sleep Res., vol.
          27, no. 6, p. e12710, Dec. 2018, doi: 10.1111/jsr.12710.
        </p>

        <p>
          <span>[3]</span> C. N. Kaufmann, R. Susukida, and C. A. Depp, 'Sleep
          apnea, psychopathology, and mental health care', Sleep Health, vol. 3,
          no. 4, pp. 244–249, Aug. 2017, doi: 10.1016/j.sleh.2017.04.003.
        </p>

        <p>
          <span>[4]</span> J. C. Mundt, A. P. Vogel, D. E. Feltner, and W. R.
          Lenderking, 'Vocal acoustic biomarkers of depression severity and
          treatment response', Biol. Psychiatry, vol. 72, no. 7, pp. 580-587,
          Oct. 2012, doi: 10.1016/j.biopsych. 2012.03.015.
        </p>

        <p>
          <span>[5]</span> L. Albuquerque, A. R. S. Valente, A. Teixeira, D.
          Figueiredo, P. Sa-Couto, and C. Oliveira, 'Association between
          acoustic speech features and non-severe levels of anxiety and
          depression symptoms across lifespan', PloS One, vol. 16, no. 4, p.
          e0248842, 2021, doi:10.1371/journal.pone.0248842.
        </p>

        <p>
          <span>[6]</span> C. Solomon, M. F. Valstar, R. K. Morriss, and J.
          Crowe, 'Objective Methods for Reliable Detection of Concealed
          Depression', Front. ICT, vol. 2, 2015, Accessed: May 04, 2022.
          [Online]. Available:
          https:/www.frontiersin.orgarticle/10.3389/fict.2015.00005
        </p>

        <p>
          <span>[7]</span> 'Crema-D'. https://paperswithcode.com/dataset/crema-d
        </p>

        <p>
          <span>[8]</span> S. R. Livingstone and F. A. Russo, 'The Ryerson
          Audio-Visual Database of Emotional Speech and Song (RAVDESS): A
          dynamic, multimodal set of facial and vocal expressions in North
          American English', PLOS ONE, vol. 13, no. 5, p. e0196391, May 2018,
          doi: 10.1371/journal.pone.0196391.
        </p>

        <p>
          <span>[9]</span> A. Zadehet al., 'Multimodal language analysis in the
          wild: CMU-MOSEI dataset and interpretable dynamic fusion graph', in
          ACL 2018-56th Annual Meeting of the Association for Computational
          Linguistics, Proceedings of the Conference (Long Papers), 2018, vol.
          1. doi: 10.18653/v1/p18-1208.
        </p>
      </div>
    </div>
  );
};

export default Resources;
