import { motion } from 'framer-motion';
import linkedin from '../assets/linkedin.svg'
import x from '../assets/x.svg'

import './style.scss';
import '../index.scss';
import herbertAndKarin from '../../../resources/images/dashboard/herbert-and-karin.png';

const AboutEmpowers = () => (
  <div className="our-story">
    <div className="our-story__container">
      <div className="left-part">
        <div className="story-text">
          <motion.h1
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'spring', duration: 1 }}
          >
            Our Story
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'tween', duration: 0.5, delay: 0.1 }}
          >
            Earkick was founded in 2021 by serial
            entrepreneurs <span>Karin Andrea Stephan.</span> and{' '}
            <span>Dr. Herbert Bay</span>
          </motion.p>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'tween', duration: 0.5, delay: 0.2 }}
          >
            We’re after revolutionizing mental health support by
            leveraging our passion for tackling global challenges and
            our backgrounds as serial entrepreneurs.
          </motion.p>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'tween', duration: 0.5, delay: 0.2 }}
          >
            Herbert is a Computer Vision expert who has successfully
            built and sold computer vision/AI startups and Karin’s
            thesis on the intersection of mental health and tech laid
            the foundation for Earkick’s mission.
          </motion.p>

          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ type: 'tween', duration: 0.5, delay: 0.2 }}
          >
            Together with an exceptional team we are building an
            AI-powered mental health platform with a multi-modal LLM
            companion that measures, tracks, communicates and helps
            improve mental health in real time.
          </motion.p>
        </div>
      </div>
      <div className="right-part">
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: 'spring', duration: 2 }}
          src={herbertAndKarin}
          alt="Herbert and Karin | CEO and Co-founder"
          loading="lazy"
        />
        <div className="about-socials herbert">
          <a
            href="https://www.linkedin.com/in/herbertbay/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="x.com" loading="lazy" />
          </a>
          <a
            href="https://x.com/bay_herbert"
            target="_blank"
            rel="noreferrer"
          >
            <img src={x} alt="Twitter" loading="lazy" />
          </a>
        </div>
        <div className="about-socials karin">
          <a
            href="https://www.linkedin.com/in/karinstephan/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="x.com" loading="lazy" />
          </a>
          <a
            href="https://x.com/mountaintribe"
            target="_blank"
            rel="noreferrer"
          >
            <img src={x} alt="Twitter" loading="lazy" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default AboutEmpowers;
