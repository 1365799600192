import React, { useRef } from 'react';
import '../style.scss';
import { useInView, motion } from 'framer-motion';
import effortlessPhone from '../../../../resources/images/dashboard/blue-phone.png';
import pic1 from '../../../../resources/images/dashboard/small-1.png';
import pic2 from '../../../../resources/images/dashboard/small-2.png';
import pic3 from '../../../../resources/images/dashboard/small-3.png';
import pic4 from '../../../../resources/images/dashboard/small-5.png';
import pic5 from '../../../../resources/images/dashboard/small-6.png';
import pic6 from '../../../../resources/images/dashboard/small-7.png';
import { useEffect } from 'react';

const scrollValues = [225, 0];
const SeaPhone = () => {
  const phoneEl = useRef(null);
  const isPhoneInView = useInView(phoneEl, { once: true });
  const section1Ref = useRef(null);

  useEffect(() => {
    if (isPhoneInView) {
      const randomScroll = () => {
        scrollValues.forEach((value, index) => {
          setTimeout(() => {
            section1Ref.current.scrollTo({
              left: value,
              behavior: 'smooth',
            });
          }, 1000 * (index + 1));
        });
      };
      randomScroll();
    }
  }, [isPhoneInView]);
  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', duration: 2 }}
      className="phone"
      ref={phoneEl}
    >
      <motion.img
        variants={{
          hidden: { y: 100 },
          show: {
            y: 0,
            transition: {
              delay: 0.6,
              duration: 1,
            },
          },
        }}
        src={effortlessPhone}
        alt="Effortless phone"
      />
      <motion.div
        variants={{
          hidden: { y: 100 },
          show: {
            y: 0,
            transition: {
              delay: 0.6,
              duration: 1,
            },
          },
        }}
        transition={{ type: 'spring', duration: 2 }}
        className="phone-container"
      >
        <div className="meditation" style={{ marginTop: '9px' }}>
          <h5>Meditation</h5>
          <div className="slider" ref={section1Ref}>
            <img src={pic1} loading="lazy" alt="meditation" />
            <img src={pic2} loading="lazy" alt="meditation" />
            <img src={pic3} loading="lazy" alt="meditation" />
          </div>
        </div>
        <div style={{ marginTop: '27px' }} className="meditation">
          <h5>Breathing</h5>
          <div className="slider">
            <img src={pic4} loading="lazy" alt="meditation" />
            <img src={pic5} loading="lazy" alt="meditation" />
            <img src={pic6} loading="lazy" alt="meditation" />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SeaPhone;
