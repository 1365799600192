import { Helmet } from 'react-helmet';
import MainBanner from 'components/Dashboard/MainBanner';

import Feature from '../../components/Dashboard/Feature';
import Info from '../../components/Dashboard/Info';
import Video from '../../components/Dashboard/Video';
import Work from '../../components/Dashboard/Work';
import MainFeaturesList from '../../components/Dashboard/MainFeaturesList';
import UserSaying from '../../components/Dashboard/UserSaying';
import TryEarkick from '../../components/Dashboard/TryEarkick';
import Faq from '../../components/Faq';

const Dashboard = ({ canonical }) => {
  return (
    <div className="main-content flex-column dashboard" style={{ background: '#FFFEF5' }}>
      <Helmet>
        <link rel="canonical" href={ canonical } />
        <title>Earkick - Your Free Personal AI Therapist Chat Bot</title>
        <meta name="description" content="Measure & improve your mental health in real time with your personal AI chat bot. No sign up. Available 24/7. Daily insights just for you!" data-react-helmet="true" />
      </Helmet>
      <MainBanner />
      <Feature />
      <Info />
      <Video />
      <Work />
      <MainFeaturesList />
      <UserSaying />
      <TryEarkick />
      <Faq />
    </div>
  );
};

export default Dashboard;
