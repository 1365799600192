import './style.scss';
import officeSanFrancisco from '../../../resources/images/dashboard/office-san-francisco.svg';
import officeZurich from '../../../resources/images/dashboard/office-zurich.svg';

const Offices = () => {
  return (
    <div className="offices">
      <div className="offices__container">
        <div className="left-style">
          <img
            src={officeSanFrancisco}
            alt="San Francisco map"
            loading="lazy"
          />
          <img src={officeZurich} alt="Zurich map" loading="lazy" />
        </div>
        <div className="right-style">
          <h2 className="h2">Offices</h2>
          <p className="p">
            We're currently operating out of the US and Switzerland
          </p>
        </div>
      </div>
    </div>
  );
};

export default Offices;
