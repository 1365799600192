import './style.scss';
import jasperImage from '../../../resources/images/jasper.png';
import ericaImage from '../../../resources/images/erica.png';
import nickImage from '../../../resources/images/nick.png';
import royImage from '../../../resources/images/roy.png';
import mikeImage from '../../../resources/images/mike.png';
import sriImage from '../../../resources/images/sri.png';
import { useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import linkedin from '../assets/linkedin.svg'

const Advisors = () => {
  const circleInstance = useRef();
  const firstElement = useRef(null);
  const isInViewFirstElement = useInView(firstElement, { once: true });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  return (
    <div className="advisor-background">
      <div className="advisors">
        <div className="advisors__container">
          <h2 className="h2" ref={circleInstance}>
            Advisors
          </h2>
          <motion.div
            variants={container}
            initial={!isInViewFirstElement && 'hidden'}
            animate={isInViewFirstElement && 'show'}
            className="content"
            ref={firstElement}
          >
            <div className="element">
              <motion.img
                variants={item}
                initial={!isInViewFirstElement && 'hidden'}
                animate={isInViewFirstElement && 'show'}
                transition={{ delay: 0.1 }}
                src={jasperImage}
                alt="Jasper Smits"
              />
              <div className="element-social">
                <a href="https://www.linkedin.com/in/jasper-smits-306294a/" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="Linkedin" loading="lazy" />
                </a>
              </div>
            </div>
            <div className="element">
              <motion.img
                variants={item}
                initial={!isInViewFirstElement && 'hidden'}
                animate={isInViewFirstElement && 'show'}
                transition={{ delay: 0.2 }}
                src={ericaImage}
                alt="Erica Simon"
              />
              <div className="element-social">
                <a href="https://www.linkedin.com/in/erica-simon-phd/" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="Linkedin" loading="lazy" />
                </a>
              </div>
            </div>
            <div className="element">
              <motion.img
                variants={item}
                initial={!isInViewFirstElement && 'hidden'}
                animate={isInViewFirstElement && 'show'}
                transition={{ delay: 0.3 }}
                src={nickImage}
                alt="Nick Evans Lombe"
              />
              <div className="element-social">
                <a href="https://www.linkedin.com/in/nick-evans-lombe-1bab765/" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="Linkedin" loading="lazy" />
                </a>
              </div>
            </div>
            <div className="element">
              <motion.img
                variants={item}
                initial={!isInViewFirstElement && 'hidden'}
                animate={isInViewFirstElement && 'show'}
                transition={{ delay: 0.4 }}
                src={royImage}
                alt="Roy Ashok"
              />
              <div className="element-social">
                <a href="https://www.linkedin.com/in/royashok/" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="Linkedin" loading="lazy" />
                </a>
              </div>
            </div>
            <div className="element">
              <motion.img
                variants={item}
                initial={!isInViewFirstElement && 'hidden'}
                animate={isInViewFirstElement && 'show'}
                transition={{ delay: 0.5 }}
                src={mikeImage}
                alt="Michael Lynch"
              />
              <div className="element-social">
                <a href="https://www.linkedin.com/in/mike-lynch-26304513/" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="Linkedin" loading="lazy" />
                </a>
              </div>
            </div>
            <div className="element">
              <motion.img
                variants={item}
                initial={!isInViewFirstElement && 'hidden'}
                animate={isInViewFirstElement && 'show'}
                transition={{ delay: 0.6 }}
                src={sriImage}
                alt="Srivignesh Rajendran"
              />
              <div className="element-social">
                <a href="https://www.linkedin.com/in/sri-rajendran/" target="_blank" rel="noreferrer">
                  <img src={linkedin} alt="Linkedin" loading="lazy" />
                </a>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Advisors;
