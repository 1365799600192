import React, { useRef } from 'react';
import '../style.scss';
import { useInView, motion } from 'framer-motion';
import effortlessPhone from '../../../../resources/images/dashboard/background-phone-1.png';
import phoneLocation from '../../../../resources/images/dashboard/phone-header-location.svg';
import arrowDown from '../../../../resources/images/dashboard/arrow-down.svg';
import secondStatsPhoto from '../../../../resources/images/dashboard/phone-second-stats.png';
import { useState } from 'react';
import { useEffect } from 'react';

const Phone = () => {
  const phoneEl = useRef(null);
  const isPhoneInView = useInView(phoneEl, { once: true });
  const [day, setDay] = useState(1);
  const [progress, setProgress] = useState(0);
  const [mood, setMoode] = useState(0);
  const [moodStability, setMoodStability] = useState(0);
  const [calmness, setCalmness] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (day < 7 && isPhoneInView) {
        setDay(day + 1);
        // setMoode(Math.floor((day + 1) * 8.72));
        // setMoodStability(Math.floor((day + 1) * 5.42));
        // setCalmness(Math.floor((day + 1) * 10.57));
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [day, isPhoneInView]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (progress < 58 && isPhoneInView) {
        setProgress(progress + 1);
      }
    }, 40);
    return () => {
      clearTimeout(interval);
    };
  }, [progress, isPhoneInView]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (mood < 61 && isPhoneInView) {
        setMoode(mood + 1);
      }
    }, 30);
    return () => {
      clearTimeout(interval);
    };
  }, [mood, isPhoneInView]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (moodStability < 38 && isPhoneInView) {
        setMoodStability(moodStability + 1);
      }
    }, 60);
    return () => {
      clearTimeout(interval);
    };
  }, [moodStability, isPhoneInView]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (calmness < 74 && isPhoneInView) {
        setCalmness(calmness + 1);
      }
    }, 30);
    return () => {
      clearTimeout(interval);
    };
  }, [calmness, isPhoneInView]);

  return (
    <motion.div
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', duration: 2 }}
      className="phone"
      ref={phoneEl}
    >
      <motion.img
        variants={{
          hidden: { y: 100 },
          show: {
            y: 0,
            transition: {
              delay: 0.6,
              duration: 1,
            },
          },
        }}
        src={effortlessPhone}
        alt="Effortless phone"
      />
      <motion.div
        variants={{
          hidden: { y: 100 },
          show: {
            y: 0,
            transition: {
              delay: 0.6,
              duration: 1,
            },
          },
        }}
        transition={{ type: 'spring', duration: 2 }}
        className="phone-container"
      >
        <div className="phone-header">
          <img src={phoneLocation} alt="location" loading="lazy" />
          <div className="phone-location">
            <div className="phone-location-container">
              <p>
                🗓 Last <span>{day}</span> days{' '}
                <img src={arrowDown} alt="arrow down" loading="lazy" />
              </p>
            </div>
          </div>
        </div>
        <div className="phone-stats">
          <div className="phone-stats-circle">
            <h3>{progress}%</h3>
            <span>Mental readiness score</span>
          </div>
          <div
            style={{ transform: `rotate(${progress * 1.8 + 180}deg)` }}
            className="phone-stats-circle-inner"
          ></div>
        </div>

        <div className="phone-second-stats">
          <div>
            <div className="container">
              <div>Mood</div>
              <div>{mood}%</div>
            </div>

            <div style={{ position: 'relative' }}>
              <div className="pink-line" style={{ width: `${mood}%` }} />

              <div className="pink-outer-line" />
            </div>
          </div>
          <div style={{ marginTop: '11px' }}>
            <div className="container">
              <div>Mood stability</div>
              <div>{moodStability}%</div>
            </div>

            <div style={{ position: 'relative' }}>
              <div
                className="pink-line"
                style={{ width: `${moodStability}%` }}
              />

              <div className="pink-outer-line" />
            </div>
          </div>
          <div style={{ marginTop: '11px' }}>
            <div className="container">
              <div>Calmness</div>
              <div>{calmness}%</div>
            </div>

            <div style={{ position: 'relative' }}>
              <div className="pink-line" style={{ width: `${calmness}%` }} />

              <div className="pink-outer-line" />
            </div>
          </div>
        </div>

        <img
          alt="stats"
          className="phone-third-stats"
          src={secondStatsPhoto}
          loading="lazy"
        />
      </motion.div>
    </motion.div>
  );
};

export default Phone;
