import './style.scss';
import pandaAnonymous from '../../../resources/images/dashboard/panda-anony.svg';
import pandaEffortless from '../../../resources/images/dashboard/panda-effortless.svg';
import pandaFree from '../../../resources/images/dashboard/panda-free.svg';
import { Suspense } from 'react';

const Info = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="info">
        <div className="info__container">
          <div className="element">
            <div className="panda yellow">
              <img
                src={pandaAnonymous}
                alt="Panda anonymous - no registration"
                loading="lazy"
              />
            </div>
            <div className="content">
              <h3 className="h3">AI powered</h3>
              <p className="p">
              Auto-detect your mental state<br />
              Get immediate <strong>guidance</strong><br />
              <strong>Talk to</strong> Earkick Panda
              </p>
            </div>
          </div>
          <div className="element">
            <div className="panda green">
              <img src={pandaEffortless} alt="Panda effortless " loading="lazy" />
            </div>
            <div className="content">
              <h3 className="h3">Effortless</h3>
              <p className="p">
              3-second check-in<br /> 
              No typing<br />
              Intuitive UX
              </p>
            </div>
          </div>
          <div className="element">
            <div className="panda blue">
              <img src={pandaFree} alt="Panda free " loading="lazy" />
            </div>
            <div className="content">
              <h3 className="h3">Radically private</h3>
              <p className="p">No registration<br />
              No personal data<br />
              No ads, only AI therapy</p>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Info;
