import './style.scss';
import BorderedText from '../../bordered-text';
import { useInView, motion } from 'framer-motion';
import effortlessBackground from '../../../resources/images/dashboard/effortless-background.svg';
import insightBackground from '../../../resources/images/dashboard/insight-background.svg';
import insightPhone from '../../../resources/images/dashboard/insight-phone.png';
import personBackground from '../../../resources/images/dashboard/person-background.svg';
import Phone from './Phone';
import React, { Suspense, useRef } from 'react';
import SeaPhone from './Phone/SeaPhone';

const Work = () => {
  const firstElement = useRef(null);
  const isInViewFirstElement = useInView(firstElement, { once: true });

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.4,
      },
    },
  };

  const item = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
    },
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="work" ref={firstElement}>
        <div className="work__container">
          <h2 className="h2">
            How does Earkick
            <BorderedText
              text="work?"
              textColor="#F074FB"
              backgroundColor="#FCD4FF"
              rotate="-4"
            />
          </h2>
          <motion.div
            className="content"
            variants={container}
            initial={!isInViewFirstElement && 'hidden'}
            animate={isInViewFirstElement && 'show'}
          >
            <motion.div className="element" variants={item}>
              <h3 className="h3">
                <span>01. </span>AI powered tracking
              </h3>
              <div className="image-container">
                <img
                  className="background"
                  src={effortlessBackground}
                  alt="Effortless background"
                />

                <Phone />
              </div>
              <p className="p">
                Understand your emotional health in real time and receive suggestions to improve.
              </p>
            </motion.div>
            <motion.div variants={item} className="element">
              <h3 className="h3">
                <span>02. </span>Real time conversations
              </h3>
              <div className="image-container">
                <img
                  className="background"
                  src={insightBackground}
                  alt="Insightful statistics background"
                />

                <motion.img
                  variants={{
                    hidden: { y: 100 },
                    show: {
                      y: 0,
                      transition: {
                        delay: 0.6,
                        duration: 1,
                      },
                    },
                  }}
                  className="phone"
                  src={insightPhone}
                  alt="Effortless phone"
                />
              </div>
              <p className="p">
              Interact with your AI therapist of choice for real time support based on your input
              </p>
            </motion.div>
            <motion.div variants={item} className="element">
              <h3 className="h3">
                <span>03. </span>Guided selfcare sessions
              </h3>
              <div className="image-container">
                <img
                  className="background"
                  src={personBackground}
                  alt="Personal statistics background"
                />

                <SeaPhone />
              </div>
              <p className="p">
                Learn to focus on recovery, find calm and become your healthiest self
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </Suspense>
  );
};

export default Work;
