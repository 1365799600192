import './style.scss';
import React, { Suspense } from 'react';
import BorderedText from '../../bordered-text';

import yellowHeadIcon from '../../../resources/images/dashboard/yellow-head-icon.svg';
import stars from '../../../resources/images/dashboard/stars.svg';

const UserSaying = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="user">
        <div className="user__container">
          <h2 className="h2">
            What our
            <BorderedText
              text="users"
              backgroundColor="#D4FFDB"
              textColor="#39DA52"
              rotate="-4"
            />
            are saying
          </h2>
          <div className="content">
            <div className="first-element">
              <div className="header-style">
                <img
                  className="circle"
                  src={yellowHeadIcon}
                  alt="Yellow head icon"
                />
                <p>Shamanix08</p>
                <div className="stars-style">
                  <img src={stars} alt="stars" loading="lazy" />
                </div>
              </div>
              <h3>Measure heart rate during sessions</h3>
              <p>
                I had no idea I need such an app! Love the integration with Apple
                Watch - I get notifications and I see that it tracks more health
                data when I use the app (those breathing exercises are
                AWESOME!!!).
              </p>
            </div>

            <div className="first-element">
              <div className="header-style">
                <img
                  className="circle"
                  src={yellowHeadIcon}
                  alt="Yellow head icon"
                />
                <p>rla1978</p>
                <div className="stars-style">
                  <img src={stars} alt="stars" loading="lazy" />
                </div>
              </div>
              <h3>Very effective that puts privacy first</h3>
              <p>
                This is one of those rare apps that actually puts your privacy
                first. I did not have to create a login or account of any kind.
                Stress and anxiety are such personal and private conditions that
                have historically prevented me from using other apps. Earkick has
                handled it really well. Overall I am impressed with the app so
                far. It reminds me to log things without being too intrusive
                and the exercises are quick enough to feel just right to calm me
                down. It has got me doing things in a regular basis which is the
                key to managing stress.
              </p>
            </div>

            <div className="first-element margin-style">
              <div className="header-style">
                <img
                  className="circle"
                  src={yellowHeadIcon}
                  alt="Yellow head icon"
                />
                <p>frogslide</p>
                <div className="stars-style">
                  <img src={stars} alt="stars" loading="lazy" />
                </div>
              </div>
              <h3>Great app for anxiety</h3>
              <p>
                As a therapist that works with anxiety daily I cannot recommend
                this app enough. Plus, they have wonderful customer service and
                are constantly improving this app.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default UserSaying;
