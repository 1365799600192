import { useState } from "react"
import "./style.scss"

const FaqItem = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggle = () => setIsOpen(!isOpen)

  return (<div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={() => toggle()}>
    <div className="indicator"></div>
    {children}
  </div>)
}

const FAQ = () => (
  <div className="faq flex flex-column">
    <h2>FAQ</h2>
  
    <FaqItem>
      <h3>How does an AI therapist app work in mental health care?</h3>
      <p>An AI therapist app uses artificial intelligence to understand and support you in mental health care while ensuring security and protecting your health information. It fosters human connection by allowing you to talk to it anytime, remembering past conversations to make interactions more personal. Using machines and chatbots, it pays attention to your voice or video, tracks patterns in how you type or move, and even considers how sleep and weather affect you. It responds in real time, provides guidance, and tracks progress—all while feeling like a natural conversation.</p>
    </FaqItem>

    <FaqItem>
      <h3>Can an AI chat bot replace a psychologist?</h3>
      <p>No, AI cannot replace psychologists or other human therapists. An AI therapist app is not a medical professional. However, it offers instant support—whether you&apos;re between psychotherapists or don&apos;t have access to a therapist. Earkick's advice uses principles from Cognitive Behavioral Therapy (CBT) and Dialectical Behavior Therapy (DBT) to provide evidence-based suggestions and practical tools for patients to manage emotions and stress.</p>
    </FaqItem>

    <FaqItem>
      <h3>Can Earkick diagnose mental health conditions?</h3>
      <p>No, Earkick is not intended to provide formal diagnoses, medical advice, or replace traditional therapy or clinical evaluations. Instead, it analyzes trends and helps you track your emotions, spot patterns, and manage stress by offering mental advice. If you are experiencing a mental health crisis, struggling with thoughts of suicide, or in need of a diagnosis, seek help from the mental health professionals. Earkick aligns with guidelines from organizations like the <a href="https://www.apa.org/research-practice/standards-guidelines" target="_blank" rel="noreferer">American Psychological Association</a> but should not be used as a substitute for professional care.</p>
    </FaqItem>

    <FaqItem>
      <h3>What does the free version of an app offer?</h3>
      <p>The free version of Earkick offers a safe place to chat with Panda, vent, feel heard and get actionable suggestions. You can personalize your Panda, get daily checkins, weekly summaries of your progress, and statistics to stay in control of your journey. The habit tracker, CBT sessions, daily quotes and the knowledge hub help your personal growth. You get breathing exercises for immediate relief and soundscapes for meditation or sleep.</p>
    </FaqItem>

    <FaqItem>
      <h3>How much does Earkick cost? </h3>
      <p>Earkick is free and you don&apos;t have to register to use it. The premium version&apos;s prices depend on your location. We offer annual and monthly subscriptions including a family plan so the whole family can benefit. <a href="https://chat.earkick.com/">Try Earkick app for free now</a>!</p>
    </FaqItem>

    <FaqItem>
      <h3>Can Earkick personalize its therapy advice?</h3>
      <p>Yes! Earkick remembers your conversations and learns from how you interact with it. Its responses and suggestions are tailored to fit your needs. You can also customize your Earkick Panda&apos;s personality and conversation style to match your preferences.</p>
    </FaqItem>

    <FaqItem>
      <h3>If you are already seeing a licensed therapist, can Earkick help you?</h3>
      <p>Yes! An AI therapist application is a great tool between therapy sessions. Earkick reinforces what you&apos;re working on, supporting your treatment by providing encouragement and motivation when you need it most. With 24/7 support, you can practice coping strategies, track your experiences, and make your therapy even more effective. It can also assist individuals with autism by offering structured guidance and personalized support.</p>
    </FaqItem>

    <FaqItem>
      <h3>Does Earkick give objective guidance and unbiased care?</h3>
      <p>Yes. Earkick&apos;s algorithms are built on proven mental health methods and continuously refined to minimize bias. Its data-driven guidance supports your mental health journey by providing non-judgmental and unbiased emotional support. With the ability to learn from real interactions and user feedback, it continuously improves to better meet your needs.</p>
    </FaqItem>

    <FaqItem>
      <h3>What mental health issues can Earkick help with?</h3>
      <p>Earkick AI chat bot helps with stress, anxiety, motivation, sleep, focus, negative self-talk, emotional regulation, relationships and self-confidence.</p>
    </FaqItem>
  </div>
)

export default FAQ
