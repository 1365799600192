import './style.scss';
import inv1 from '../../../resources/images/dashboard/inv-1.svg';
import inv2 from '../../../resources/images/dashboard/inv-2.svg';

const Investors = () => {
  return (
    <div className="investors">
      <div className="investors__container">
        <h2 className="h2">Investors</h2>
        <p className="p">
          Earkick wouldn't exist without our investors who share our vision.
        </p>
        <div className="content">
          <img src={inv1} alt="LDV Capital investor" loading="lazy" />
          <img src={inv2} alt="West tech ventures investor" loading="lazy" />
        </div>
      </div>
    </div>
  );
};

export default Investors;
