import Advisors from 'components/About/Advisors';
import Offices from 'components/About/Offices';
import TryEarkick from 'components/Dashboard/TryEarkick';
import AboutEmpowers from '../../components/About/AboutEmpowers';
import Investors from '../../components/About/Investors/index';
import { Helmet } from 'react-helmet';

const About = ({ canonical }) => {
  return (
    <div className="main-content flex-column about" style={{ background: '#fffef5b' }}>
      <Helmet>
        <link rel="canonical" href={ canonical } />
        <title>About Our Free AI Chat Bot Earkick</title>
        <meta name="description" content="Earkick, the free AI chat bot&apos;s designed to support your mental well-being. Learn about our mission, features, and how we help users manage stress and anxiety." data-react-helmet="true" />
      </Helmet>
      <AboutEmpowers />
      <Advisors />
      <Offices />
      <Investors />
      <TryEarkick />
    </div>
  );
};

export default About;
