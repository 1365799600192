import './style.scss';
import React, { Suspense } from 'react';
import earkickBear from '../../../resources/images/dashboard/earkick-bear-heart.svg';
import appStoreDownload from '../../../resources/images/app-store.webp';
import googlePlayDownload from '../../../resources/images/google-play-badge.png';
import PoweredByElevenLabs from '../../../resources/images/Powered_by_ElevenLabs.png'

const androidDownload = false;

const TryEarkick = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="try-earkick">
        <div className="try-earkick__container">
          <div className="circle-earkick">
            <img
              className="earkick-bear"
              src={earkickBear}
              alt="Earkick bear"
              loading="lazy"
            />
          </div>
          <h2>Try Earkick today!</h2>
          <p>Track and improve your mental health in real time</p>
          <div className="download-apps">
            <a
              href="https://earkick.go.link?adj_t=1gmtqodf&adj_campaign=tryearkick"
              target="_blank"
              className="app-store" 
              rel="noreferrer"
            >
              <img
                src={appStoreDownload}
                alt="Appstore download button"
                loading="lazy"
              />
            </a>
            {androidDownload && 
              <a
                className="google-play" 
                href='https://play.google.com/store/apps/details?id=com.earkick.SelfCareCompanion&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                <img alt='Get it on Google Play' src={googlePlayDownload} />
              </a>
              }
          </div>

          <div className="powered-by-eleven-labs">
            <div className="pre-text">Panda&apos;s voice is</div>
            <img
              src={PoweredByElevenLabs}
              alt="Powered By Eleven Labs"
              loading="lazy"
              
            />

          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default TryEarkick;
