import { Helmet } from 'react-helmet';
import TryEarkick from 'components/Dashboard/TryEarkick';
import HealthPredictions from 'components/Technologies/HealthPredictions';
import Resources from 'components/Technologies/Resources';
import MentalHealth from '../../components/Technologies/MentalHealth/index';
import Novelty from '../../components/Technologies/Novelty/index';

const Technologies = ({canonical}) => {
  return (
    <div className="main-content flex-column technologies" style={{ background: '#fffef5b' }}>
      <Helmet>
        <link rel="canonical" href={ canonical } />
        <title>Technologies - How AI Earkick Chat Bot Work</title>
        <meta name="description" content="The advanced AI technology behind Earkick. Learn how our chatbot processes emotions, provides real-time support, and enhances mental well-being." data-react-helmet="true" />
      </Helmet>
      <MentalHealth />
      <HealthPredictions />
      <Novelty />
      <Resources />
      <TryEarkick />
    </div>
  );
};

export default Technologies;
