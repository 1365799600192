import React from 'react';
import './style.scss';
import l1 from '../../../resources/images/technologies/l1.svg';
import l2 from '../../../resources/images/technologies/l2.svg';
import l3 from '../../../resources/images/technologies/l3.svg';
import l4 from '../../../resources/images/technologies/l4.svg';
import l5 from '../../../resources/images/technologies/l5.svg';
import l6 from '../../../resources/images/technologies/l6.svg';
import l7 from '../../../resources/images/technologies/l7.svg';

import r1 from '../../../resources/images/technologies/r1.svg';
import r2 from '../../../resources/images/technologies/r2.svg';
import r3 from '../../../resources/images/technologies/r3.svg';
import r4 from '../../../resources/images/technologies/r4.svg';
import r5 from '../../../resources/images/technologies/r5.svg';
import r6 from '../../../resources/images/technologies/r6.svg';
import r7 from '../../../resources/images/technologies/r7.svg';

const HealthPredictions = () => {
  return (
    <div className="health-predictions-background">
      <div className="health-predictions">
        <div className="health-predictions__container">
          <h2 className="h2">
            The data that we're considering <br /> for mental health prediction
            is:
          </h2>
          <div className="content">
            <div className="part">
              {data[0].left.map((item) => {
                return (
                  <div key={`${item.id}-left`} className="element">
                    <img src={item.image} alt={item.text} loading="lazy" />
                    <h2>{item.text}</h2>
                  </div>
                );
              })}
            </div>
            <div className="part">
              {data[0].right.map((item) => {
                return (
                  <div key={`${item.id}-right`} className="element">
                    <img src={item.image} alt={item.text} loading="lazy" />
                    <h2>{item.text}</h2>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthPredictions;

const data = [
  {
    left: [
      {
        id: 1,
        name: '',
        image: l1,
        text: `Audio, video and text memo's and typing behavior when users describe their day, their mood and anxiety levels.`,
      },
      {
        id: 2,
        name: '',
        image: l2,
        text: 'Tasks performed by the user which go toward achieving their goals (e.g. sleeping more than 7 hrs).',
      },
      {
        id: 3,
        name: '',
        image: l3,
        text: 'Sleep duration',
      },
      {
        id: 4,
        name: '',
        image: l4,
        text: 'Exercises',
      },
      {
        id: 5,
        name: '',
        image: l5,
        text: 'Meditations / Mindfulness minutes',
      },
      {
        id: 6,
        name: '',
        image: l6,
        text: 'Heart Rate measurements',
      },
      {
        id: 7,
        name: '',
        image: l7,
        text: 'Post-processed features such as voice pitch, jitter, speech rate, and in the visual domain, facial action units like the movement of eyebrows, eye gaze and eye blink rate',
      },
    ],
    right: [
      {
        id: 1,
        name: '',
        image: r1,
        text: 'Context information such as work, friends, exercise that led to how users are feeling.',
      },
      {
        id: 2,
        name: '',
        image: r2,
        text: 'Symptoms that are of clinical use in diagnosis of mental health issues. For example a user may record a symptom like “Nervousness” or “Constantly worrying” which correspond to clinical assessment questions in forms like Generalized Anxiety Disorder 7.',
      },
      {
        id: 3,
        name: '',
        image: r3,
        text: 'Menstrual cycle',
      },
      {
        id: 4,
        name: '',
        image: r4,
        text: 'Location data, if the user permits us to use it.',
      },
      {
        id: 5,
        name: '',
        image: r5,
        text: 'Weather data such as sunlight hours and cloud coverage if the user permits us to use location',
      },
      {
        id: 6,
        name: '',
        image: r6,
        text: 'We extract features from timestamps of when people type.',
      },
      {
        id: 7,
        name: '',
        image: r7,
        text: 'Panic attack time, location, duration, intensity and symptoms',
      },
    ],
  },
];
